<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header column box-style">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.spot_detail.station") }} <small>*</small></label>
            <select name="station" id="station" v-model="modelSpot.stationId" :class="{'invalid': submitted && v$.modelSpot.stationId.$invalid}" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.station") }}</option>
              <option v-for="station in listStations" :value="station.id" :key="station.id">{{ station.name }}</option>
            </select> 
          </div>
          <div class="settings-input">
            <label id="num_int">{{ $t("workspace.spot_detail.num_int") }}</label>
            <input type="text" name="num_int" v-model="modelSpot.internalOrderNumber" readonly disabled>
          </div>
          <div class="settings-input">
            <label id="num_ord">{{ $t("workspace.spot_detail.num_ord") }}</label>
            <input type="text" name="num_ord" v-model="modelSpot.agencyOrderNumber" autocomplete="off" :placeholder="`${$t('workspace.spot_detail.enter')} ${$t('workspace.spot_detail.num_ord')}`" @change="changeInput">
          </div>
          <div class="settings-input">
            <label for="discount">{{ $t("workspace.spot_detail.discount") }} (%)</label>
            <input type="text" autocomplete="off" id="discount" v-model="modelSpot.discount" @change="changeInput"> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.spot_detail.raw") }}</label>
            <input type="text" autocomplete="off" id="name" v-model="modelSpot.monthlyFee" @change="changeInput">
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label for="agency">{{ $t("workspace.spot_detail.agency") }}</label>
            <select id="agency" v-model="modelSpot.adAgencyId" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.agency") }}</option>  
              <option v-for="agency in listAgencies" :value="agency.id" :key="agency.id">{{ agency.code }} - {{ agency.name }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label for="origin">{{ $t("workspace.spot_detail.origin") }}</label>
            <select id="origin" v-model="modelSpot.originId" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.origin") }}</option>  
              <option v-for="origin in listOrigin" :value="origin.id" :key="origin.id">{{ origin.code }} - {{ origin.description }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label for="pay_method">{{ $t("workspace.spot_detail.pay_method") }}</label>
            <select id="pay_method" v-model="modelSpot.payMethodId" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.pay_method") }}</option>  
              <option v-for="method in listMethod" :key="method.id" :value="method.id">{{method.code}} - {{method.description}}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label for="discount2">{{ $t("workspace.spot_detail.discount") }} 2 (%)</label>
            <input type="text" autocomplete="off" id="discount2" v-model="modelSpot.discount2" @change="changeInput"> 
          </div>
          <div class="settings-input">
            <label for="procentage">{{ $t("workspace.spot_detail.porcentage") }} (%)</label>
            <input type="text" autocomplete="off" id="porcentage" v-model="modelSpot.adAgencyPercentage" @change="changeInput"> 
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label for="customer">{{ $t("workspace.spot_detail.customer") }} <small>*</small></label>
            <select id="customer" v-model="modelSpot.clientId" :class="{'invalid': submitted && v$.modelSpot.clientId.$invalid}" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.customer") }}</option>  
              <option v-for="client in listClients" :value="client.id" :key="client.id">{{client.code}} - {{ client.name }}</option>  
            </select> 
          </div>
        </div>
        <div class="container__inputs">
          <div class="settings-input">
            <label for="line">{{ $t("workspace.spot_detail.line_of_business") }} <small>*</small></label>
            <select id="line" v-model="modelSpot.spotCategoryId" :class="{'invalid': submitted && v$.modelSpot.spotCategoryId.$invalid}" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.line_of_business") }}</option>  
              <option v-for="spots in listSpots" :value="spots.id" :key="spots.id">{{spots.code}} - {{ spots.name }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label for="executive">{{ $t("workspace.spot_detail.executive") }}</label>
            <select id="executive"  v-model="modelSpot.accountExecutiveId" @change="changeInput">
              <option :value="null" selected>{{ $t("workspace.spot_detail.select") }} {{ $t("workspace.spot_detail.executive") }}</option>  
              <option v-for="executive in listAccountExecutive" :value="executive.id" :key="executive.id">{{executive.code}} - {{ executive.name }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label for="discount3">{{ $t("workspace.spot_detail.discount") }} 3 (%)</label>
            <input type="text" autocomplete="off" id="discount3" v-model="modelSpot.discount3" @change="changeInput"> 
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label for="name">{{ $t("workspace.spot_detail.name") }} <small>*</small></label>
            <input type="text" id="name" v-model="modelSpot.name" autocomplete="off" :placeholder="`${$t('modal.placeholder_insert')} ${$t('workspace.spot_detail.name')}`" :class="{'invalid': submitted && v$.modelSpot.name.$invalid}" @change="changeInput">
          </div>
          <div class="settings-input double-column">
            <label for="comments">{{ $t("workspace.spot_detail.comments") }}</label>
            <textarea v-model="modelSpot.comment" name="comments" id="comments" cols="30" rows="10" :placeholder="`${$t('modal.placeholder_insert')} ${$t('workspace.spot_detail.comments')}`" @change="changeInput"></textarea>
          </div>
          <div class="settings-input">
            <label for="discount4">{{ $t("workspace.spot_detail.discount") }} 4 (%)</label>
            <input type="text" autocomplete="off" id="discount4" v-model="modelSpot.discount4" @change="changeInput"> 
          </div>
        </div>
      </div>        
    </div>

    <div style="margin-bottom: 12px;">
      <div class="workspace__container__view__table__header">
        <h3>{{ $t("workspace.spot_detail.detail_programming") }}</h3>
        <div class="settings-input">
          <input type="month" v-model="detailProgrammingMonthSelect" @change="filterAdvancedSchedules" :disabled="spotAdvancedSchedulesFiltered.length > 0">
        </div>
        <button class="outline small" v-if="detailProgrammingMonthSelect != ''" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_DETAILED-SHEDULE_CREATE-EDIT', {monthSelect: detailProgrammingMonthSelect})">{{$t("workspace.schedules.add")}}</button>
      </div>
      <table class="workspace__container__view__table">
        <thead>
          <tr>
            <th>{{ $t("workspace.spot_detail.time_init") }}</th>
            <th>{{ $t("workspace.spot_detail.time_end") }}</th>
            <th>{{ $t("workspace.spot_detail.file") }}</th>
            <th>{{ $t("workspace.spot_detail.fee") }}</th>
            <th v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" :key="i" class="center" 
              :class="{'hover': positionHover.x >= 0 && positionHover.x == i - 1}">
                <small style="font-weight: normal; opacity: .7; font-size: 10px">{{getDateInMonth(i)}}</small><br>
                {{i}}
            </th>
            <th class="center">{{ $t("workspace.spot_detail.actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(spot, idxSpot) in orderBy(spotAdvancedSchedulesFiltered, 'startTime')" :key="spot.id">
            <td>{{spot.startTime}}</td>
            <td>{{spot.endTime}}</td>
            <td>{{spot.fileName}}</td>
            <td>{{spot.timeFee}}</td>
            <td 
              v-for="i in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]" 
              :key="i" class="center" 
              :class="{
                'with-value': spot.occurrences[i] > 0,
                'hover': (positionHover.x >= i && positionHover.y == idxSpot) || (positionHover.x == i && positionHover.y >= idxSpot)
              }"
              @mouseover="setPositionHover(i, idxSpot)"
              @mouseleave="setPositionHover(-1, -1)">
              <input 
                type="number" 
                class="input__occurrences"
                v-model="spot.occurrences[i]"
                @focus="$event.target.select()"
                @focusout="spot.occurrences[i] == '' ? spot.occurrences[i] = 0 : null"
                @keyup.enter="$event.target.parentElement.nextElementSibling.childNodes[0].focus()"
                @change="changeInput">
            </td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_DETAILED-SHEDULE_CREATE-EDIT', {...spot, edit: true})">edit</i>
              <i class="material-icons" @click="deleteItem(spot, 'EMIT_removeAdvancedSchedule')">delete</i>
            </td>
          </tr>
          <tr v-if="spotAdvancedSchedulesFiltered.length == 0">
            <td colspan="36">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("workspace.spot_detail.detail_programming") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_DETAILED-SHEDULE_CREATE-EDIT', {monthSelect: detailProgrammingMonthSelect})">{{ $t("workspace.schedules.add") }}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="border-top: 1px solid #ffffff52; padding-top: 12px;">
      <div class="workspace__container__view__table__header">
        <h3>{{ $t("workspace.spot_detail.simple_programming") }}</h3>
        <button class="outline small" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_SIMPLE-SCHEDULE_CREATE-EDIT')">{{$t("workspace.schedules.add")}}</button>
      </div>
      <table class="workspace__container__view__table">
        <thead>
          <tr>
            <th>{{ $t("workspace.spot_detail.date_init") }}</th>
            <th>{{ $t("workspace.spot_detail.date_end") }}</th>
            <th>{{ $t("workspace.spot_detail.file") }}</th>
            <th class="center">{{ $t("days_of_week.monday") }}</th>
            <th class="center">{{ $t("days_of_week.tuesday") }}</th>
            <th class="center">{{ $t("days_of_week.wednesday") }}</th>
            <th class="center">{{ $t("days_of_week.thursday") }}</th>
            <th class="center">{{ $t("days_of_week.friday") }}</th>
            <th class="center">{{ $t("days_of_week.saturday") }}</th>
            <th class="center">{{ $t("days_of_week.sunday") }}</th>
            <th>{{ $t("workspace.spot_detail.detail") }}</th>
            <th class="center">{{ $t("workspace.spot_detail.actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="spot in orderBy(modelSpot.spotSimpleSchedules, 'startDate')" :key="spot.id">
            <td>{{formatDate(spot.startDate)}}</td>
            <td>{{formatDate(spot.endDate)}}</td>
            <td>{{spot.filename}}</td>
            <td class="center"><i class="material-icons">{{spot.monday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.tuesday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.wednesday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.thursday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.friday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.saturday == 1 ? 'done': 'close'}}</i></td>
            <td class="center"><i class="material-icons">{{spot.sunday == 1 ? 'done': 'close'}}</i></td>
            <td>
              <template v-if="spot.timeMode == 0">{{spot.startTime}} - {{spot.endTime}} ({{spot.occurrences}})</template>
              <template v-if="spot.timeMode == 1">{{getShowById(spot.showId)}} ({{spot.occurrences}})</template>
              <template v-if="spot.timeMode == 2">{{spot.exactTimes}}</template>
            </td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_SIMPLE-SCHEDULE_CREATE-EDIT', {...spot, edit: true})">edit</i>
              <i class="material-icons" @click="deleteItem(spot, 'EMIT_removeSimpleSchedule')">delete</i>
            </td>
          </tr>
          <tr v-if="modelSpot.spotSimpleSchedules.length == 0">
            <td colspan="12">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("workspace.spot_detail.simple_programming") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit('SPOT-PROGRAMMING_SPOT_SIMPLE-SCHEDULE_CREATE-EDIT')">{{ $t("workspace.schedules.add") }}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="workspace__container__view__actions">
      <div>
        <button disabled class="disabled">{{ $t("workspace.spot_detail.copy_commercial") }}</button>
        <button disabled class="disabled">{{ $t("workspace.spot_detail.cancel_replace") }}</button>
      </div>
      <button @click="modelSpot.id ? update() : save()">{{ $t("workspace.spot_detail.save") }}</button>
      <button disabled class="disabled">{{ $t("workspace.spot_detail.save_test") }}</button>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import persistentData from '@/plugins/persistentData';
import useVuelidate from '@vuelidate/core';
import eventBus from '@/plugins/eventBus';
import moment from 'moment';

export default {
  setup() {
		const v$ = useVuelidate();
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const listStations = computed(() => store.getters.station_stations({name: ''}));
    const listAgencies = computed(() => store.getters.adAgency_agencies({name: '', code: '', adAgencyGroupId: '', taxCode: ''}));
    const listOrigin = computed(() => store.getters.origin_origin({code: '', description: ''}));
    const listClients = computed(() => store.getters.client_clients({code: '', name: '', taxCode: ''}));
    const listMethod = computed(() => store.getters.payMethod_method({code: '', description: ''}));
    const listSpots = computed(() => store.getters.spotsCategory_spots({code: '', name: ''}));
    const listAccountExecutive = computed(() => store.getters.accountExecutive_accountExecutive({code: '', name: ''}));
    const getShowById = computed(() => showId => store.getters.show_programById(showId));
    const orderBy = computed(() => (list, property) => list.sort((a, b) => moment(a[property], 'hh:mm').isBefore(moment(b[property], 'hh:mm')) ? -1 : 1));
    const detailProgrammingMonthSelect = ref(moment().format('YYYY-MM'));
    const modelSpot = ref({
      spotCategoryId: null,
      comment: "",
      clientId: null,
      adAgencyId: null,
      monthlyFee: 0,
      discount: 0,
      adAgencyPercentage: 0,
      dueDate: null,
      internalOrderNumber: 0,
      internalOrderNumberRevision: 0,
      agencyOrderNumber: "",
      accountExecutiveId: null,
      discount2: 0,
      name: "",
      initDate: null,
      month: null,
      originId: null,
      payMethodId: null,
      discount3: 0,
      discount4: 0,
      stationId: null,
      spotAdvancedSchedules: [],
      spotSimpleSchedules: []
    });
    const internalReferenceSpot = new Date().getUTCMilliseconds();
    const spotAdvancedSchedulesFiltered = ref([]);
    const submitted = ref(false);
    const positionHover = ref({
      x: -1,
      y: -1
    })
  
    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      
      await store.dispatch('station_getAll');
      await store.dispatch('adAgency_getAll');
      await store.dispatch('origin_getAll');
      await store.dispatch('client_getAll');
      await store.dispatch('payMethod_getAll');
      await store.dispatch('spotsCategory_getAll');
      await store.dispatch('accountExecutive_getAll');
      await store.dispatch('show_getAll');

      const tab = store.getters.tabs_getActive;
      if (tab.extendedData.changes) {
        modelSpot.value = {...tab.extendedData};
        filterAdvancedSchedules();
        
        store.dispatch('tabs_updateTab', {
          id: route.params.id,
          extendedData: tab.extendedData
        });
      } else {
        if (!route.params.id.includes('create')) {
          const response = await store.dispatch('spots_getById', {id: route.params.id})
          if (listStations.value.length == 1) modelSpot.value.stationId = listStations.value[0].id;
          modelSpot.value = {...response};
          filterAdvancedSchedules();
        }
  
        store.dispatch('tabs_updateTab', {
          id: route.params.id,
          extendedData: modelSpot.value
        });
      }

      // Setear valor en el input de mes
      if (modelSpot.value.spotAdvancedSchedules.length > 0) {
        detailProgrammingMonthSelect.value = moment(modelSpot.value.spotAdvancedSchedules[0].date).format('YYYY-MM');
        filterAdvancedSchedules();
      }

      eventBus.$on('addSimpleSchedule', (model) => {
        // Validación por si esta editando/creando en varias pestañas
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        modelSpot.value.spotSimpleSchedules.push(model);
        changeInput();
      });
      eventBus.$on('editSimpleSchedule', (model) => {
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        const positionIndex = modelSpot.value.spotSimpleSchedules.findIndex(schedule => schedule.id == model.id);
        modelSpot.value.spotSimpleSchedules[positionIndex] = model;
        changeInput();
      });
      eventBus.$on('removeSimpleSchedule', (model) => {
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        modelSpot.value.spotSimpleSchedules = modelSpot.value.spotSimpleSchedules.filter(schedule => schedule.id != model.id);
        changeInput();
      });
      eventBus.$on('addAdvancedSchedule', (model) => {
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        modelSpot.value.spotAdvancedSchedules.push(model);
        filterAdvancedSchedules();
        changeInput();
      });
      eventBus.$on('editAdvancedSchedule', (model) => {
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        const positionIndex = modelSpot.value.spotAdvancedSchedules.findIndex(schedule => schedule.id == model.id);
        modelSpot.value.spotAdvancedSchedules[positionIndex] = model;
        filterAdvancedSchedules();
        changeInput();
      });
      eventBus.$on('removeAdvancedSchedule', (model) => {
        if (model.internalReferenceSpot != internalReferenceSpot) return;
        delete model['internalReferenceSpot'];
        modelSpot.value.spotAdvancedSchedules = modelSpot.value.spotAdvancedSchedules.filter(schedule => schedule.id != model.id);
        filterAdvancedSchedules();
        changeInput();
      });
      store.dispatch('loading_setActive', false);
    });

    onUnmounted(() => {
      eventBus.$off('addSimpleSchedule');
      eventBus.$off('editSimpleSchedule');
      eventBus.$off('removeSimpleSchedule');
      eventBus.$off('addAdvancedSchedule');
      eventBus.$off('editAdvancedSchedule');
      eventBus.$off('removeAdvancedSchedule');
    });

    async function createOrEdit(active, item) {
      await store.dispatch('modal_setActive', {active: active, props: {internalReferenceSpot, ...item}});
    }

    async function deleteItem(item, actionDelete) {
      const modelDelete = {};
      switch (actionDelete) {
        case 'EMIT_removeSimpleSchedule':
          modelDelete.id = item.id;
          modelDelete.startDate = formatDate(item.startDate);
          modelDelete.endDate = formatDate(item.endDate);
          modelDelete.fileName = item.filename;
          modelDelete.monday = item.monday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.tuesday = item.tuesday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.wednesday = item.wednesday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.thursday = item.thursday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.friday = item.friday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.saturday = item.saturday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          modelDelete.sunday = item.sunday == 1 ? t("workspace.schedules.active"): t("workspace.schedules.disabled");
          if (item.timeMode == 0) modelDelete.detail = `${item.startTime} - ${item.endTime} (${item.occurrences})`;
          if (item.timeMode == 1) modelDelete.detail = `${this.getShowById(item.showId)} (${item.occurrences})`;
          if (item.timeMode == 2) modelDelete.detail = `${item.exactTimes}`;
          break;
        case 'EMIT_removeAdvancedSchedule':
          modelDelete.id = item.id;
          modelDelete.startTime = item.startTime;
          modelDelete.endTime = item.endTime;
          modelDelete.fileName = item.fileName;
          modelDelete.timeFee = item.timeFee;
          break;
      }
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: {internalReferenceSpot, ...modelDelete}, actionDelete});
    }

    function formatDate(date) {
      if (!date) return '-';
      return moment(date).format('L');
    }

    function getDateInMonth(date) {
      const month = Number(detailProgrammingMonthSelect.value.split('-')[1]);
      return moment().locale(persistentData.get('lang')).set({'month': (month - 1), date}).format('ddd')
    }

    async function save() {
      submitted.value = true;
      if (!v$.value.modelSpot.$invalid) {
        try {
          const modelResponse = await store.dispatch('spots_create', modelSpot.value)
          modelSpot.value = modelResponse;
          const tab = store.getters.tabs_getActive;
          tab.id = modelSpot.value.id;
          await store.dispatch('tabs_updateTab', {
            ...tab,
            extendedData: modelSpot.value
          })
          router.push(`/${tab.module}/${tab.name}/${tab.id}`);
          submitted.value = false;
        } catch (error) {
          console.log(error);
        }
      }
    }

    async function update() {
      submitted.value = true;
      if (!v$.value.modelSpot.$invalid) {
        store.dispatch('spots_update', modelSpot.value);
        const tab = store.getters.tabs_getActive;
        await store.dispatch('tabs_updateTab', {
          ...tab,
          extendedData: modelSpot.value
        })
        submitted.value = false;
      }
    }

    function setPositionHover(x, y) {
      positionHover.value.x = x;
      positionHover.value.y = y;
    }

    function filterAdvancedSchedules() {
      const month = Number(detailProgrammingMonthSelect.value.split('-')[1]);
      console.log(modelSpot.value.spotAdvancedSchedules);
      spotAdvancedSchedulesFiltered.value = modelSpot.value.spotAdvancedSchedules.filter(spot => moment(spot.date, 'YYYY-MM-DD').month() == (month - 1));
    }

    async function changeInput() {
      const tab = store.getters.tabs_getActive;
      await store.dispatch('tabs_updateTab', {
        ...tab,
        extendedData: modelSpot.value
      })
      await store.dispatch('tabs_setPropChanges', {id: route.params.id, changes: true});
    }

    watch(() => route.params.id, () => {
      const tab = store.getters.tabs_getActive;
      modelSpot.value = tab.extendedData;
      if (tab.module == "spot_programming" && tab.name == "spot/detail") {
        filterAdvancedSchedules();
      }
    }); 

    return {
      v$,
      listStations,
      listAgencies,
      listOrigin,
      listClients,
      listMethod,
      listSpots,
      listAccountExecutive,
      getShowById,
      modelSpot,
      positionHover,
      detailProgrammingMonthSelect,
      spotAdvancedSchedulesFiltered,
      submitted,
      orderBy,
      createOrEdit,
      deleteItem,
      formatDate,
      getDateInMonth,
      setPositionHover,
      save,
      update,
      filterAdvancedSchedules,
      changeInput
    }
  },
  validations () {
    return {
      modelSpot: {
        stationId: {required},
        clientId: {required},
        spotCategoryId: {required},
        name: {required}
			}
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-style {
    background: #202020;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
  }

  .input__occurrences {
    width: 30px; 
    background: none; 
    border: none; 
    color: white;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none;
    }
  }

  .with-value {
    background: #606060;
  }

  th, td {
    &.hover:not(.with-value) {
      background: #6060607e !important;
      transition: .1s;
      cursor: pointer;
    }
  }
</style>